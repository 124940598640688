<template>
  <div
    class="OperatorChat"
    :class="[isOpen ? 'Open' : 'Collapsed']"
  >
    <div
      class="Header tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-px-4"
      @click="toggle"
    >
      <div
        class="tw-text-base tw-font-semibold tw-w-full tw-flex tw-items-center tw-gap-0.5"
      >
        <span>
          {{ title ? title : receiverName }}
        </span>

        <el-badge
          v-if="!chat.open && countUnreadMessages > 0"
          :value="countUnreadMessages"
          :max="99"
          type="danger"
          class="tw-ml-1 tw-flex"
        />
      </div>
      <div
        v-if="closeable"
        class="tw-cursor-pointer tw-ml-3"
        @click.stop="close"
      >
        <icon-close class="tw-block tw-w-3.5 tw-h-auto" />
      </div>
    </div>
    <div
      v-show="isOpen"
      class="Body"
    >
      <!-- TODO enable file upload -->
      <ChatFeed
        :messages="chat.messages"
        :submit-disabled="submitDisabled"
        :allow-upload="false"
        :slim="true"
        :is-visible="isOpen"
        @resend-message="() => {}"
        @submit-message="submitMessage"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chat: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sendingMessage: false,
    };
  },
  computed: {
    isOpen() {
      return !!this.chat.open;
    },
    submitDisabled() {
      return this.sendingMessage || !this.wsConnected;
    },
    wsConnected() {
      return this.$store.state.websocket.isConnected;
    },
    operatorId() {
      return this.chat.receiver_id;
    },
    roomId() {
      return this.chat.room_id;
    },
    me() {
      return useAuthStore().user;
    },
    isOrganization() {
      if (!this.chat.receiver) { return false }
      return this.chat.receiver.type == 2;
    },
    receiverName() {
      if (!this.chat.receiver) { return '' }

      if (this.isOrganization) { return this.chat.receiver.name }

      if (this.chat.receiver.first_name && this.chat.receiver.last_name) { return `${this.chat.receiver.first_name} ${this.chat.receiver.last_name}` }

      if (this.chat.receiver.full_name) { return this.chat.receiver.full_name }

      return '';
    },

    countUnreadMessages() {
      return this.chat.messages.filter((message) => message.is_read === false && !message.is_typing).length;
    },
  },
  watch: {
    wsConnected: {
      immediate: true,
      handler(isConnected) {
        if (isConnected) {
          this.fetchMessages();
        }
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch('chat/closeChat', {
        receiver_id: this.operatorId,
        room_id: this.roomId,
      });
    },
    toggle(event) {
      if (event && event.target.closest('.icon')) {
        return;
      }

      if (this.chat.open) {
        this.$store.dispatch('chat/hideChat', {
          receiver_id: this.operatorId,
          room_id: this.roomId,
        });
      } else {
        this.$store.dispatch('chat/openChat', {
          room_id: this.roomId,
          receiver_id: this.operatorId,
          receiver: this.chat.receiver,
        });
      }
    },
    async submitMessage(text, { isTyping, isMessageByThirdParty }) {
      this.sendingMessage = true;

      const tempId = Math.random();
      const created_at = dayjs().format();

      const sendMessage = {
        content: text,
        created_at,
        temp_id: tempId,
        id: tempId,
        receiver_id: this.operatorId,
        room_id: this.roomId,
        is_typing: isTyping || false,
        third_party: isMessageByThirdParty || false,
        sender: this.me,
        status: 'pending',
      };

      await this.$store.dispatch('chat/sendMessage', sendMessage);

      this.sendingMessage = false;
    },
    fetchMessages() {
      const params = {
        receiver_id: this.operatorId,
        room_id: this.roomId,
      };

      this.$store.dispatch('chat/fetchMessages', params);
    },
  },
};
</script>

<style scoped>
.OperatorChat {
  box-shadow: 0 25px 35px rgb(0 0 0 / 0.15);

  .Header {
    height: 36px;
    @apply tw-bg-primary-500;
    @apply tw-text-white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .Body {
    @apply tw-bg-white;
  }

  &.Open {
    width: 364px;

    .Body {
      height: 420px;
      max-height: 60vh;
    }
  }

  &.Collapsed {
    max-width: 250px;
  }
}
</style>
